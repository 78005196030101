<template>
  <div class="transaction">
    <div class="row">
      <div class="flex md12">
        <div class="mb-2" style="font-size: 24px;">Member Lists</div>
      </div>
    </div>
    <div class="row">
      <div class="flex md12">
        <va-card>
          <va-card-content>
            <div class="row">
              <div class="flex xs12 md4">
                <va-input v-model="search" placeholder="Email" />
              </div>
              <div class=" flex xs12 md2">
                <va-button color="primary" @click="getUserList()">
                  Search
                </va-button>
              </div>
              <div class="flex xs12 md6 text--right">
                <span>
                  <va-button @click="downloadUserEmail()" color="success">
                    <span>Download</span>
                    <va-icon class="ml-2" name="download"></va-icon>
                  </va-button>
                </span>
              </div>
            </div>
          </va-card-content>
        </va-card>
      </div>
    </div>
    <div class="row transaction-tables">
      <div class="flex md12 xl12">
        <va-card :title="$t('tables.stripedHoverable')">
          <va-card-content>
            <div class="table-wrapper">
              <table class="va-table va-table--striped va-table--hoverable">
                <thead>
                  <tr>
                    <th>Created At</th>
                    <th>Email</th>
                    <th>Username</th>
                  </tr>
                </thead>

                <tbody>
                  <tr class="p-4" v-for="user in users" :key="user.id">
                    <td>
                      {{ new Date(user.createdAt).toString().substr(0, 15) }}
                    </td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.username }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </va-card-content>
        </va-card>
      </div>
    </div>
    <div class="row">
      <div class="flex xs12 xl12">
        <div class="d-flex justify--center">
          <va-button
            icon="ion-ios-arrow-back"
            outline
            class="mr-2"
            :disabled="!lastEvaluatedKey"
            @click="getUserList('prev')"
          >
            <span class="ml-2">Prev</span>
          </va-button>
          <va-button
            icon-right="ion-ios-arrow-forward"
            class="ml-2"
            outline
            :disabled="!lastEvaluatedKey"
            @click="getUserList('next')"
          >
            <span class="mr-2">Next</span>
          </va-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from "aws-amplify";
import { API_NAME } from "@/api/APIConfig";
import ExcelJS from "exceljs";

export default {
  async mounted() {
    await this.getUserList();
  },
  data() {
    return {
      users: [],
      lastEvaluatedKey: null,
      search: ""
    };
  },
  methods: {
    async getUserList(step) {
      try {
        let body = {};
        if (step === "next") {
          body = {
            LastEvaluatedKey: this.lastEvaluatedKey
          };
        }
        if (step === "prev") {
          body = {
            prev: true,
            LastEvaluatedKey: this.lastEvaluatedKey
          };
        }
        if (this.search !== "") {
          Object.assign(body, { email: this.search });
        }
        const result = await API.post(API_NAME, `/admin/get-user-list`, {
          body
        });
        this.users = result.Items;
        if (result.LastEvaluatedKey) {
          this.lastEvaluatedKey = result.LastEvaluatedKey;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getAllUserList() {
      try {
        let result = await API.post(API_NAME, `/admin/get-all-user-list`);

        let users = [...result.Items];

        if (result.LastEvaluatedKey) {
          do {
            result = await API.post(
              API_NAME,
              `/admin/get-all-user-list?pk=${result.LastEvaluatedKey.pk}&sk=${result.LastEvaluatedKey.sk}&createdAt=${result.LastEvaluatedKey.createdAt}`
            );
            users = [...users, result.Items];
          } while (result.LastEvaluatedKey);
        }
        return users;
      } catch (error) {
        console.log(error);
      }
    },

    async downloadUserEmail() {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = "Survein";
      workbook.lastModifiedBy = "Survein";
      workbook.created = new Date();

      const worksheet = workbook.addWorksheet("Survein-users");

      const header = worksheet.addRow(["No", "Username", "Email"]);
      header.eachCell(cell => {
        cell.font = {
          bold: true
        };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFEFEFEF" }
        };
      });

      let index = 1;

      const allUserList = await this.getAllUserList();

      for (const user of allUserList) {
        worksheet.addRow([index, user.username, user.email]);

        worksheet.getColumn(1).width = 5;
        worksheet.getColumn(2).width = 20;
        worksheet.getColumn(3).width = 35;
        worksheet.getColumn(4).width = 15;
        index++;
      }

      const buffer = await workbook.xlsx.writeBuffer();
      this.downloadBlob(
        buffer,
        "survein-users.xlsx",
        "application/octet-stream"
      );
    },

    downloadURL(data, fileName) {
      const a = document.createElement("a");
      a.href = data;
      a.download = fileName;
      document.body.appendChild(a);
      a.style.display = "none";
      a.click();
      a.remove();
    },

    downloadBlob(data, fileName, mimeType) {
      const blob = new Blob([data], {
        type: mimeType
      });
      const url = window.URL.createObjectURL(blob);
      this.downloadURL(url, fileName);
      setTimeout(() => window.URL.revokeObjectURL(url), 1000);
    }
  }
};
</script>

<style lang="scss">
.transaction-tables {
  .table-wrapper {
    overflow: auto;
  }

  .va-table {
    width: 100%;
  }
}
</style>
